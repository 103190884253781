import Layout from "./components/layout/Layout";


function App() {
    return (
        <div className="App">
            <Layout/>
        </div>
    );
}

export default App;

