export const TRANSLATIONS_CA = {
    home: "Inici",
    title: "Et fem la vida més fàcil",
    subtitle: "Paga aquí els teus rebuts online",
    result_title: "Resum de l'operació",
    ko_message: "Error!",
    ok_message: "Succés!!",
    ok_message_time: "Operació realitzada el {{day}} a les {{time}} amb Codi d'autorització: {{authCode}}",
    ok_button: "Continueu",
    ko_button: "Torneu a intentar l'operació",
    policyNumber: "Pòlissa",
    receiptNumber: "Rebut",
    amount: "Total",
    amountTotal: "Import total",
    concept: "Concepte",
    orderNbr: "Ref. Comanda",
    merchantData: "Comerç",
    pay: "Pagar",
    header: "Pagament en línea",
    errorUrl: "La URL sol·licitada no es correcta",
    readyRedsys: "Error preparant la petició a la passarel·la de pagament",
    catalan: "Català",
    spanish: "Español",
    msg_mandatory_field: 'Aquest camp es obligatori',
    msg_different_pass: 'Aquest camps han de ser iguals',
    msg_min_length_pass: 'El camp ha de contenir almenys 8 caràcters',
    msg_date_rangemin_field: 'La data de inici ha de ser més petita que la de fi',
    msg_date_rangemax_field: 'La data de fi ha de ser més gran que la de inici',
    msg_mandatory_select_field: 'Seleccioneu almenys una de les opcions',
    msg_mandatory_equal_field: 'El camp de confirmació de email no coincideix',
    msg_mandatory_only_one_select_field: 'No es poden seleccionar les dos cobertures alhora',
    msg_not_found_content: "No s'ha trobat el contingut",
    msg_error_server: "Hi ha hagut un error al servidor de dades",
    msg_error_400: "Les dades proporcionades son incorrectes",
    msg_error_redsys: "Hi ha hagut un error de comunicació amd Redsys",
    msg_error_not_type: "Hi ha hagut un error no tipificat",
    page_not_found: "Pàgina no trobada",
};