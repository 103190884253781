import React, {Fragment, useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import "../translation/i18n";
import {Alert, Button, Card, Col, Container, Form, Row, InputGroup} from "react-bootstrap";
import {faBan, faCheck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import axiosInstance from "../utilities/axios";
import {APP_API_FINISH_PAYMENT, APP_LANG_REDSYS_REVERSE_OPTIONS} from "../utilities/constants";
import GlobalContext from "../contexts/GlobalContext";
import dateFormat from "dateformat";
import MessageResponse from "./common/MessageResponse";
import {config} from "@fortawesome/fontawesome-svg-core";
FontAwesomeIcon.autoAddCss = false
config.autoAddCss = false

const Resume = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(window.location.search)
    const [langOptions] = useState(JSON.parse(APP_LANG_REDSYS_REVERSE_OPTIONS))
    const {setActiveLanguage} = useContext(GlobalContext);
    const initialFormData = {
        dsSignatureVersion: '',
        dsMerchantParameters: '',
        dsSignature: '',
    }
    const [formData, updateFormData] = useState(initialFormData)
    const [readyParams, setReadyParams] = useState(false)
    const [responseData, updateResponseData] = useState(null)
    const [errorResponse, setErrorResponse] = useState(false)

    useEffect(() => {
            if (readyParams) {
                axiosInstance
                    .post(APP_API_FINISH_PAYMENT, formData)
                    .then((res) => {
                        updateResponseData(res.data)
                        setActiveLanguage(langOptions[res.data.language])
                    }).catch((err) => {
                    setErrorResponse(err)
                });
            }
        },
        // eslint-disable-next-line
        [readyParams]
    )

    const handleContinue = (e) => {
        window.location.href = process.env.REACT_APP_URL_REDIRECT
    };

    const handleAttempt = (e) => {
        navigate(
            '/?policyNumber=' + responseData.policyNumber +
            '&receiptNumber=' + responseData.receiptNumber +
            '&amount=' + responseData.amount)
    };

    useEffect(() => {
            if (formData) {
                setReadyParams(!Object.keys(formData).filter(i => (formData[i] === '')).length > 0)
            }
        },
        // eslint-disable-next-line
        [formData]
    )

    useEffect(() => {
            updateFormData({
                dsSignatureVersion: queryParams.get("Ds_SignatureVersion") ? queryParams.get("Ds_SignatureVersion") : '',
                dsMerchantParameters: queryParams.get("Ds_MerchantParameters") ? queryParams.get("Ds_MerchantParameters") : '',
                dsSignature: queryParams.get("Ds_Signature") ? queryParams.get("Ds_Signature") : '',
            })
        },
        // eslint-disable-next-line
        []
    )

    return (
        <Container className={'px-0 px-md-2'}>
            <Row className="justify-content-center">
                <Col md={8}>

                    <Card>
                        <Card.Header className={'p-4'}>
                            <h2 className={'text-primary mb-2'}>{t("result_title")}</h2>
                        </Card.Header>
                        <Card.Body className="p-4">
                            {responseData ?
                                <Fragment>
                                    <Form>
                                        <Row className="mb-1">
                                            <Form.Group as={Col} sm={12} md={3} controlId="receiptNumber"
                                                        className={'mb-3'}>
                                                <Form.Label>{t("receiptNumber")}</Form.Label>
                                                <Form.Control type="text" value={responseData.receiptNumber}
                                                              disabled={'disabled'}/>
                                            </Form.Group>
                                            <Form.Group as={Col} sm={12} md={3} controlId="policyNumber"
                                                        className={'mb-3'}>
                                                <Form.Label>{t("policyNumber")}</Form.Label>
                                                <Form.Control type="text" value={responseData.policyNumber}
                                                              disabled={'disabled'}/>
                                            </Form.Group>
                                            <Form.Group as={Col} sm={12} md={3} controlId="orderNbr"
                                                        className={'mb-3'}>
                                                <Form.Label>{t("orderNbr")}</Form.Label>
                                                <Form.Control type="text" value={responseData.orderNbr}
                                                              disabled={'disabled'}/>
                                            </Form.Group>
                                            <Form.Group as={Col} sm={12} md={3} controlId="amount"
                                                        className={'mb-3 '}>
                                                <Form.Label>{t("amountTotal")}</Form.Label>
                                                <InputGroup className="mb-3">
                                                    <Form.Control type="text" value={responseData.amount}
                                                                  disabled={'disabled'}/>
                                                    <InputGroup.Text>€</InputGroup.Text>
                                                </InputGroup>
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-1">
                                            <Form.Group as={Col} sm={12} md={12} controlId="concept"
                                                        className={'mb-3'}>
                                                <Form.Label>{t("concept")}</Form.Label>
                                                <Form.Control type="text" value={responseData.concept}
                                                              disabled={'disabled'}/>
                                            </Form.Group>
                                        </Row>
                                    </Form>

                                    {responseData.responseOk ?

                                        <Fragment>
                                            <Alert variant={'primary'} className={'mt-4'}>
                                                <h3 className={'pb-3'}><FontAwesomeIcon icon={faCheck}
                                                                                        className={'me-2'}/> {t("ok_message")}
                                                </h3>
                                                <p>{responseData.responseText}</p>
                                                <p>
                                                    {t("ok_message_time", {
                                                        day: dateFormat(responseData.date, "dd-mm-yyyy"),
                                                        time: responseData.time,
                                                        authCode: responseData.authCode
                                                    })}
                                                </p>
                                            </Alert>
                                            <div className={'text-center pt-3'}>
                                                <Button onClick={handleContinue}>{t("ok_button")}</Button>
                                            </div>
                                        </Fragment>

                                        :
                                        <Fragment>
                                            <Alert variant={'danger'} className={'mt-4'}>

                                                <h3 className={'alert-danger'}><FontAwesomeIcon icon={faBan}
                                                                                                className={'me-2'}/> {t("ko_message")}
                                                </h3>
                                                <p>{responseData.responseText}</p>


                                            </Alert>
                                            <div className={'text-center pt-3'}>
                                                <Button onClick={handleAttempt}>{t("ko_button")}</Button>
                                            </div>
                                        </Fragment>
                                    }

                                </Fragment>
                                : null}
                            {errorResponse ?
                                <MessageResponse error={errorResponse}/>
                                : null

                            }
                        </Card.Body>
                    </Card>

                </Col>

            </Row>

        </Container>
    );
};

export default Resume;
