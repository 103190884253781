import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import './scss/style.scss';
import App from './App';
import {GlobalProvider} from "./contexts/GlobalContext";

const container = document.getElementById('root');

const root = ReactDOMClient.createRoot(container);

root.render(
    <GlobalProvider>
        <App/>
    </GlobalProvider>
);