import React, {Fragment, useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import "../translation/i18n";
import {Button, Card, Col, Container, Form, InputGroup, Row} from "react-bootstrap";
import MessageResponse from "./common/MessageResponse";
import {APP_API_INIT_PAYMENT, APP_LANG_REDSYS_OPTIONS} from "../utilities/constants";
import axiosInstance from "../utilities/axios";
import GlobalContext from "../contexts/GlobalContext";

const Home = () => {
    const {t} = useTranslation();
    const queryParams = new URLSearchParams(window.location.search)
    const [langOptions] = useState(JSON.parse(APP_LANG_REDSYS_OPTIONS))
    const {activeLanguage} = useContext(GlobalContext);
    const initialRedsysFormData = {
        dsSignatureVersion: null,
        dsMerchantParameters: null,
        dsSignature: null,
    }

    const initialFormData = {
        policyNumber: '',
        receiptNumber: '',
        amount: '',
    }

    const [formData, updateFormData] = useState(initialFormData)
    const [redsysFormData, updateRedsysFormData] = useState(initialRedsysFormData)
    const [errors] = useState({})
    const [errorResponse, setErrorResponse] = useState(false)
    const [readyParams, setReadyParams] = useState(false)
    const [readyRedsys, setReadyRedsys] = useState(false)

    const handleChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    };

    const handleRedsysChange = (e) => {
        updateRedsysFormData({
            ...redsysFormData,
            [e.target.id]: e.target.value,
        });
    };

    useEffect(() => {
            if (redsysFormData) {
                setReadyRedsys(Object.keys(redsysFormData).filter(i => (redsysFormData[i] !== null)).length > 0)
            }
        },
        // eslint-disable-next-line
        [redsysFormData]
    )

    useEffect(() => {
            if (readyParams) {
                axiosInstance
                    .post(APP_API_INIT_PAYMENT, formData)
                    .then((res) => {
                        updateRedsysFormData(res.data)
                    }).catch((err) => {
                    setErrorResponse(err)
                });
            }
        },
        // eslint-disable-next-line
        [readyParams, formData.language]
    )

    useEffect(() => {
            if (formData) {
                setReadyParams(!Object.keys(formData).filter(i => (formData[i] === '')).length > 0)
            }
        },
        // eslint-disable-next-line
        [formData]
    )

    useEffect(() => {
            updateFormData({
                language: langOptions[activeLanguage],
                policyNumber: queryParams.get("policyNumber") ? queryParams.get("policyNumber") : '',
                receiptNumber: queryParams.get("receiptNumber") ? queryParams.get("receiptNumber") : '',
                amount: queryParams.get("amount") ? queryParams.get("amount") : '',
            })
        },
        // eslint-disable-next-line
        [activeLanguage]
    )

    return (
        <Container className={'px-0 px-md-2'}>
            <Row className="justify-content-center">
                <Col md={8}>
                    <Card>
                        <Card.Header className={'p-4'}>
                            <h2 className={'text-primary mb-2'}>{t("title")}</h2>
                            <h3 className={'text-black mb-0'}>{t("subtitle")}</h3>
                        </Card.Header>
                        <Card.Body className="p-4">
                            {readyParams ?
                                <Fragment>
                                    <Form>
                                        <Row className="mb-1">
                                            <Form.Group as={Col} sm={12} md={5} controlId="receiptNumber"
                                                        className={'mb-3 required'}>
                                                <Form.Label>{t("receiptNumber")}</Form.Label>

                                                <Form.Control type="text" value={formData.receiptNumber}
                                                              onChange={handleChange}
                                                              disabled={'disabled'}
                                                              isInvalid={!!errors.receiptNumber}/>

                                            </Form.Group>
                                            <Form.Group as={Col} sm={12} md={5} controlId="policyNumber"
                                                        className={'mb-3 required'}>
                                                <Form.Label>{t("policyNumber")}</Form.Label>

                                                <Form.Control type="text" value={formData.policyNumber}
                                                              disabled={'disabled'}
                                                              onChange={handleChange}
                                                              isInvalid={!!errors.policyNumber}/>

                                            </Form.Group>
                                            <Form.Group as={Col} sm={12} md={2} controlId="amount"
                                                        className={'mb-3 required'}>
                                                <Form.Label>{t("amount")}</Form.Label>
                                                <InputGroup className="mb-3">
                                                    <Form.Control type="text" value={formData.amount}
                                                                  onChange={handleChange}
                                                                  disabled={'disabled'}
                                                                  isInvalid={!!errors.amount}/>
                                                    <InputGroup.Text>€</InputGroup.Text>
                                                </InputGroup>
                                            </Form.Group>
                                        </Row>
                                    </Form>
                                    {readyRedsys ?
                                        <Form action={redsysFormData.redsysUrl} method={'POST'}>
                                            <Row className="mb-1">
                                                <Form.Group as={Col} sm={12} md={4} controlId="Ds_MerchantParameters"
                                                            className={'mb-3 required'}>
                                                    <Form.Control type="hidden"
                                                                  value={redsysFormData.dsMerchantParameters}
                                                                  name={'Ds_MerchantParameters'}
                                                                  onChange={handleRedsysChange}/>
                                                </Form.Group>
                                                <Form.Group as={Col} sm={12} md={4} controlId="Ds_SignatureVersion"
                                                            className={'mb-3 required'}>
                                                    <Form.Control type="hidden"
                                                                  value={redsysFormData.dsSignatureVersion}
                                                                  name={'Ds_SignatureVersion'}
                                                                  onChange={handleRedsysChange}/>
                                                </Form.Group>
                                                <Form.Group as={Col} sm={12} md={4} controlId="Ds_Signature"
                                                            className={'mb-3 required'}>
                                                    <Form.Control type="hidden" value={redsysFormData.dsSignature}
                                                                  name={'Ds_Signature'}
                                                                  onChange={handleRedsysChange}/>
                                                </Form.Group>

                                            </Row>
                                            <Row className="justify-content-between">
                                                <Col className={'text-end'}>
                                                    <Button type={'submit'}
                                                            className="btn btn-primary">{t("pay")}</Button>
                                                </Col>
                                            </Row>
                                        </Form> :
                                        null
                                    }
                                </Fragment>

                                :
                                <MessageResponse error={t("errorUrl")}/>
                            }
                            {errorResponse ?
                                <MessageResponse error={errorResponse}/>
                                : null

                            }
                        </Card.Body>
                    </Card>

                </Col>

            </Row>

        </Container>
    );
};

export default Home;
