import React, {createContext, useMemo, useState} from 'react';
import i18n from "i18next";

const GlobalContext = createContext();


const GlobalProvider = ({children}) => {

    const [activeLanguage, setActiveLanguage] = useState(i18n.language)

    const data = useMemo(() => ({
        activeLanguage,
        setActiveLanguage
        // eslint-disable-next-line
    }), [activeLanguage]);

    return (
        <GlobalContext.Provider value={data}>
            {children}
        </GlobalContext.Provider>
    )
}

export {GlobalProvider}
export default GlobalContext;