import React, {useEffect, useState} from 'react';
import {Alert} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import "../../translation/i18n";

const MessageResponse = ({error, info}) => {
    const {t} = useTranslation();
    const [message, setMessage] = useState(null);
    const [isShown, setIsShown] = useState(true);

    useEffect(() => {
            if (error) {
                if (error.response) {
                    const {status, data} = error.response
                    if (status) {
                        if (status === 404) {
                            setMessage(t('msg_not_found_content'))
                        } else if (status.toString().startsWith('5')) {
                            setMessage(t('msg_error_server'))
                        } else if (status === 401) {
                            setMessage(t('msg_error_server'))
                        } else if (status === 400) {
                            setMessage(t('msg_error_400'))
                        }else {
                            setMessage(data.error)
                        }
                    } else {
                        setMessage(t('msg_error_not_type'))
                    }
                } else {
                    if (error.message) {
                        setMessage(error.message)
                    } else {
                        setMessage(error)
                        setTimeout(() => {
                            setIsShown(false)
                        }, 5000);
                    }
                }
            }


            if (info) {
                setIsShown(true);
                setMessage(info)
                setTimeout(() => {
                    setIsShown(false)
                }, 5000);
            }

        },
        // eslint-disable-next-line
        [error, info]
    )

    useEffect(() => {
            if (!isShown) {
                setTimeout(() => {
                    setMessage(null)
                }, 2000);
            }

        },
        // eslint-disable-next-line
        [isShown]
    )

    return (

        message ?
            <div className={'message-box'}>


                <Alert show={isShown} variant={error ? 'danger' : 'primary'} className={'mt-4'}>
                    {message}
                </Alert>


            </div>
            : null

    );
};

export default MessageResponse;
