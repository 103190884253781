import React, {useContext, useState} from 'react';
import logo from "../../images/logo.svg";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import "../../translation/i18n";
import i18n from "i18next";
import {Dropdown} from "react-bootstrap";
import {APP_LANG_OPTIONS} from "../../utilities/constants";
import GlobalContext from "../../contexts/GlobalContext";

const Header = () => {
    const {t} = useTranslation();

    const {activeLanguage, setActiveLanguage} = useContext(GlobalContext);
    const [langOptions] = useState(JSON.parse(APP_LANG_OPTIONS))

    const handlerChangeLanguage = (lang) => {
        setActiveLanguage(lang)
        i18n.changeLanguage(lang);
    };

    return (

        <header id="top-bar" className="navbar-fixed-top ">
            <div className="container">

                <div className="navbar-header d-flex align-items-center justify-content-between">

                    <div className="navbar-brand">
                        <Link to="/">
                            <img src={logo} className="App-logo" alt="logo"/>
                        </Link>
                    </div>
                    <div>
                        <h1>{t("header")}</h1>
                    </div>

                    <nav className="navbar-toggler navbar-toggler-right" role="navigation" id="nav-1">
                        <Dropdown className={'user-dropdown'}>
                            {/*<Dropdown.Toggle variant="link" id="dropdown-basic">*/}
                            {/*    <span className={'ms-2  text-gray-600 '}>{langOptions[activeLanguage]}</span>*/}
                            {/*</Dropdown.Toggle>*/}

                            {/*<Dropdown.Menu className={'dropdown-menu-right navbar-dropdown  py-2'}>*/}
                            {/*    <Dropdown.Item onClick={e => handlerChangeLanguage(e.target.dataset.value)}*/}
                            {/*                   data-value={'ca'}>*/}
                            {/*        {t('catalan')}*/}
                            {/*    </Dropdown.Item>*/}
                            {/*    <Dropdown.Item onClick={e => handlerChangeLanguage(e.target.dataset.value)}*/}
                            {/*                   data-value={'es'}>*/}
                            {/*        {t('spanish')}*/}
                            {/*    </Dropdown.Item>*/}
                            {/*</Dropdown.Menu>*/}
                        </Dropdown>

                    </nav>

                </div>
            </div>

        </header>

    )
}

export default Header;