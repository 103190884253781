import React from 'react';
import {Route, Routes,} from "react-router-dom";
import Home from "../Home";
import NoMatch from "../NoMatch";
import Resume from "../Resume";


const Content = () => {

    return (
        <section id="hero-area" className={'py-5 my-4'}>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path={process.env.REACT_APP_URL_RESUME} element={<Resume/>}/>
                <Route path="*" element={<NoMatch/>}/>
            </Routes>
        </section>
    );
};

export default Content;

