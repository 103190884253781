export const TRANSLATIONS_ES = {
    home: "Inici",
    title: "Te hacemos la vida más fácil",
    subtitle: "Paga aquí tus recibos online",
    result_title: "Resumen de la operación",
    ko_message: "Error!",
    ok_message: "Éxito!!",
    ok_message_time: "Operación realizada el {{day}} a las {{time}} con Código de autorización: {{authCode}}",
    ok_button: "Continuar",
    ko_button: "Volver a intentar la operación",
    policyNumber: "Póliza",
    receiptNumber: "Recibo",
    amount: "Total",
    amountTotal: "Importe total",
    concept: "Concepto",
    orderNbr: "Ref. Comanda",
    merchantData: "Comercio",
    pay: "Pagar",
    header: "Pago en línea",
    errorUrl: "La URL solicitada no es correcta",
    readyRedsys: "Error preparando la petición en la pasarela de pago",
    catalan: "Català",
    spanish: "Español",
    msg_mandatory_field: 'Este campo es obligatori',
    msg_persons_field: 'Los campos de los asegurados deben ser debidamente cumplimentados. Asegurese de haver añadido todos los asegurados',
    msg_different_pass: 'Estos campos deben ser iguales',
    msg_min_length_pass: 'El campo debe contener al menos 8 caracteres',
    msg_date_rangemin_field: 'La fecha de inicio debe ser menor que la de fin',
    msg_date_rangemax_field: 'La fecha de fin debe ser mayor que la de inicio',
    msg_mandatory_select_field: 'Seleccione al menos una de las opciones',
    msg_mandatory_equal_field: 'El campo de confirmación de email no coincide',
    msg_mandatory_only_one_select_field: 'No se pueden seleccionar ambas coberturas a la vez',
    msg_fail_delete: "Error en el aplicativo. El elemento no se pudo borrar",
    title_delete: "¿Está seguro de borrar este elemento?",
    button_delete: "Elimina el registro",
    msg_not_found_content: "No se ha encontrado el contenido",
    msg_error_server: "Ha habido un error en el servidor de datos",
    msg_error_redsys: "Ha habido un error de comunicación con Redsys",
    msg_error_400: "Los datos proporcionados son incorrectos",
    msg_error_not_type: "Ha habido un error no tipificado",
    page_not_found: "Pàgina no trobada",
};