import React, {Fragment} from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import Header from "./Header";
import Content from "./Content";

const Layout = () => {

    return (

        <Router>

            <Fragment>
                <Header/>
                <Content/>
            </Fragment>


        </Router>

    );
};

export default Layout;
