import React from 'react';
import {faBan} from "@fortawesome/free-solid-svg-icons";
import { config } from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import "../translation/i18n";
FontAwesomeIcon.autoAddCss = false
config.autoAddCss = false


const NoMatch = () => {
    const {t} = useTranslation();

    return (
        <div className={'d-flex justify-content-center pt-5'}>
            <div className={'h2 text-center'}>
                {t('page_not_found')}
                <br/><br/><FontAwesomeIcon icon={faBan} />
            </div>

        </div>
    );
};

export default NoMatch;
